import { Component, OnInit } from '@angular/core';
import { environment } from 'Sheard/environment';
import { publicService } from 'Sheard/public-service.service';
import { ToastrService } from 'ngx-toastr';
import { Slider } from '../../../Models/Slider.model';
import { img } from '../../../Models/img.model';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'sb-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {
  url:string="";
  sliders:Slider[]=[];
  BASE_IMG_URL = environment.serverUrl;
  constructor(public service: publicService,private toastr: ToastrService,private sanitization:DomSanitizer) { }

  ngOnInit(): void {
    this.GetSliders();
  }

  GetSliders()
  {
    this.service.getData("Api/Sliders/getAllSliders", "").subscribe(
      (res) => {
        this.sliders=res;
      },
      (err) => { 

      })
  }
  Update()
  {
    this.service.post(this.sliders, "Api/Sliders/update", "").subscribe(
      (res) => {
        this.sliders=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Sliders updated :) ', 'Success');
      },
      (err) => { 

      })
  }

  onMainImageChoose(mainimag:any,index:number)
  {
    if (mainimag.target.files[0]) {
     
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.sliders[index].ImageUrl=event.target.result;
      }
      reader.readAsDataURL(mainimag.target.files[0]);
  }
}
getsrc(image:string)
{
  debugger;

  if(image.includes("base"))
  {
     return  this.sanitization.bypassSecurityTrustUrl( image.split(this.BASE_IMG_URL.slice(0,-1))[1]);
  }
  else
  {
     return image;
  }
}
}
