import { Component, OnInit } from '@angular/core';
import { Topics } from '@modules/maindata/Models/Topics.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'Sheard/environment';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  mainObject:Topics[];
  BASE_URL = environment.serverUrl;
  constructor(public service: publicService,private toastr: ToastrService) 
  {
  this.mainObject=[];
  this.getUnConfirmedTopics();
  }

  ngOnInit(): void {
  }


  getUnConfirmedTopics()
  {
    this.service.getData("Api/Topics/getUnConfirmedTopics").subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        console.log(data);
      },
      error => {
      });

  }
  getConfirmedTopics()
  {
    this.service.getData("Api/Topics/getConfirmedTopics").subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        console.log(data);
      },
      error => {
      });
    
  }
  Confirm(Id:any)
  {
    this.service.getData("Api/Topics/Confirm/"+Id).subscribe(
      data => {
       this.getUnConfirmedTopics();
      },
      error => {
      });
  }
  deletepost(Id:any)
  {
    this.service.getData("Api/Topics/DeletePost/"+Id).subscribe(
      data => {
       this.getUnConfirmedTopics();
      },
      error => {
      });
  }

  deletetopic(Id:any)
  {
    this.service.getData("Api/Topics/DeleteTopic/"+Id).subscribe(
      data => {
       this.getUnConfirmedTopics();
      },
      error => {
      });
  }
}
