import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CaseStudy, CaseStudyRes, S_CaseStudies_Details, S_CaseStudies_RandC } from '@modules/maindata/Models/CaseStudy.model';
import { environment } from 'Sheard/environment';
import { FileUploadService } from 'Sheard/file-upload.service';
import { publicService } from 'Sheard/public-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sb-case-study-edit',
  templateUrl: './case-study-edit.component.html',
  styleUrls: ['./case-study-edit.component.scss']
})
export class CaseStudyEditComponent implements OnInit {
  
  CaseStudies:CaseStudy[];
  mainObject: CaseStudyRes;
  mainObjects: CaseStudy;
  CategoryIds:string[]=[];

  relationimg:string="";
  Conflictimg:string="";

  relationimgres:string="";
  Conflictimgres:string="";
  mainimgres:string="";
  url:string="";

  myexcelFiles:File [] = [];
  myvideoFiles:File [] = [];
  mypdfFiles:File [] = [];
  myimageFiles:File [] = [];

  constructor(private route: ActivatedRoute,public service: publicService,private toastr: ToastrService,public fileUpload:FileUploadService) 
  { 
      this.CaseStudies = [];
      this.mainObject = new CaseStudyRes();
      this.mainObjects=new CaseStudy();
     
  }

  id:any;
  ngOnInit(): void {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id =params.get('pid');
      })

    this.getCasestudyDetails(this.id);
  }
  geturl(url:any)
  {
    if(String(url).includes("www"))
    return url;
    else
    return environment.serverUrl+""+url;
  }
  getCasestudyDetails(id:number)
  {
    this.service.getData("Api/CaseStudies/"+id, "").subscribe(
      (data) => {
        if(data!=null)
        {
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date?.slice(0, 10);
 

        if(this.mainObject.CategoryId?.includes(","))
        {
        this.CategoryIds=this.mainObject.CategoryId?.slice(0, -1).split(",");
        }
        else
        {
          this.CategoryIds=this.mainObject.CategoryId?.split(",")||[];
        }
         
        if(this.mainObject.S_CaseStudies_Conflicts)
        {
          var url = environment.serverUrl+""+this.mainObject.S_CaseStudies_Conflicts.ImageUrl
         this.Conflictimgres=url;
        }

        if(this.mainObject.S_CaseStudies_Relations)
        {
          var url = environment.serverUrl+""+this.mainObject.S_CaseStudies_Relations.ImageUrl;
          this.relationimgres=url;
        
          // imageUrlToBase64(url).then((base64Image: any) => {
          //   this.relationimg=base64Image;
          // })
          // .catch((error: any) => {
          //   // Handle the error
          // });
        }

        if(this.mainObject.ImageUrl)
        {
          var url = environment.serverUrl+""+this.mainObject.ImageUrl;
          this.mainimgres=url;
        }

        }
      },
      (err) => { 
  
      })

  }

  

  Update()
  {

    this.mainObjects.Id=this.mainObject.Id;
    this.mainObjects.CategoryId=this.mainObject.CategoryId;
    this.mainObjects.Date=this.mainObject.Date;
    this.mainObjects.ImageUrl=this.mainObject.ImageUrl;
    this.mainObjects.LongDescription=this.mainObject.LongDescription;
    this.mainObjects.LongDescriptionAr=this.mainObject.LongDescriptionAr;
    this.mainObjects.ShortDescription=this.mainObject.ShortDescription;
    this.mainObjects.ShortDescriptionAr=this.mainObject.ShortDescriptionAr;
    this.mainObjects.Title=this.mainObject.Title;
    this.mainObjects.TitleAr=this.mainObject.TitleAr;
    this.mainObjects.Type=this.mainObject.Type;

    

    this.mainObjects.S_CaseStudies_Conflicts.push(new S_CaseStudies_RandC(0,0,this.mainObject.S_CaseStudies_Conflicts?.Description|| '',this.Conflictimg));
    this.mainObjects.S_CaseStudies_Relations.push(new S_CaseStudies_RandC(0,0,this.mainObject.S_CaseStudies_Relations?.Description|| '',this.relationimg));
    
    this.service.post(this.mainObjects, "Api/CaseStudies/UpdateMainData", "").subscribe(
      (res) => {
       this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Case Study Updated :) ', 'Success');
      },
      (err) => { 

      })



  }

onMainImageChoose(mainimag:any)
  {
    if (mainimag.target.files && mainimag.target.files[0]) {
     
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.url=event.target.result;
          this.mainObject.ImageUrl=event.target.result;
      }
      reader.readAsDataURL(mainimag.target.files[0]);
      //this.onUpload(mainimag.target.files[0]);
  }
}
onRelationImageChoose(mainimag:any)
{
  if (mainimag.target.files && mainimag.target.files[0]) {
   
    var reader = new FileReader();
    reader.onload = (event:any) => {
        this.relationimg=event.target.result;
        
    }
    reader.readAsDataURL(mainimag.target.files[0]);
    //this.onUpload(mainimag.target.files[0]);
}
}
onConflictImageChoose(mainimag:any)
{
  if (mainimag.target.files && mainimag.target.files[0]) {
   
    var reader = new FileReader();
    reader.onload = (event:any) => {
        this.Conflictimg=event.target.result;
    }
    reader.readAsDataURL(mainimag.target.files[0]);
    //this.onUpload(mainimag.target.files[0]);
}
}

deleteexcel(id:any)
{
  this.service.get("api/CaseStudies/deleteexcel/"+id, "","").subscribe(
    (res) => {
      this.getCasestudyDetails(this.id);
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success(' excel Deleted :) ', 'Success');
    },
    (err) => { 

    })
}
deletepdf(id:any)
{
  this.service.get("api/CaseStudies/deletepdf/"+id, "","").subscribe(
    (res) => {
      this.getCasestudyDetails(this.id);
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success(' pdf Deleted :) ', 'Success');
    },
    (err) => { 

    })
}
deleteimgs(id:any)
{
  this.service.get("api/CaseStudies/deleteimgs/"+id, "","").subscribe(
    (res) => {
      this.getCasestudyDetails(this.id);
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success(' imgs Deleted :) ', 'Success');
    },
    (err) => { 

    })
}
deletevideo(id:any)
{
  this.service.get("api/CaseStudies/deletevideo/"+id, "","").subscribe(
    (res) => {
      this.getCasestudyDetails(this.id);
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success(' video Deleted :) ', 'Success');
    },
    (err) => { 

    })
}

onExcelFileChange(event:any)
{
  for (var i = 0; i < event.target.files.length; i++) { 
    var reader = new FileReader();
    reader.onload = (ev:any) => {
        this.mainObjects.S_CaseStudies_Excel.push(new S_CaseStudies_Details(0,this.id,"",String(ev.target.result)));
    }
    reader.readAsDataURL(event.target.files[i]);
    this.myexcelFiles.push(event.target.files[i]);
}

}
onVideoFileChange(event:any)
{
  for (var i = 0; i < event.target.files.length; i++) { 
    var reader = new FileReader();
    reader.onload = (ev:any) => {
        this.mainObjects.S_CaseStudies_Video.push(new S_CaseStudies_Details(0,this.id,"",String(ev.target.result)));
    }
    reader.readAsDataURL(event.target.files[i]);
    this.myvideoFiles.push(event.target.files[i]);
}

}

onPDFFileChange(event:any)
{
  for (var i = 0; i < event.target.files.length; i++) { 
    var reader = new FileReader();
    reader.onload = (ev:any) => {
        this.mainObjects.S_CaseStudies_PDF.push(new S_CaseStudies_Details(0,this.id,"",String(ev.target.result)));
    }
    reader.readAsDataURL(event.target.files[i]);
    this.mypdfFiles.push(event.target.files[i]);
}

}

onImageFileChange(event:any)
{
  for (var i = 0; i < event.target.files.length; i++) { 

    var reader = new FileReader();
    reader.onload = (ev:any) => {
        this.mainObjects.S_CaseStudies_Img.push(new S_CaseStudies_Details(0,this.id,"",String(ev.target.result)));
    }
    reader.readAsDataURL(event.target.files[i]);


    this.myimageFiles.push(event.target.files[i]);
}
   
}

uploadexcel()
{

  
    for (var i = 0; i < this.myexcelFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myexcelFiles[i].name)).value;     
          this.mainObjects.S_CaseStudies_Excel[i].Title=inputValue;
    }
   
    this.service.post(this.mainObjects.S_CaseStudies_Excel, "api/CaseStudies/addexcel", "").subscribe(
      (res) => {
       this.mainObjects.S_CaseStudies_Excel=[];
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Excel Saved :) ', 'Success');
      },
      (err) => { 

      })



}
uploadImage()
{
  debugger;
  for (var i = 0; i < this.myimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.myimageFiles[i].name)).value;     
           this.mainObjects.S_CaseStudies_Img[i].Title=inputValue;
    }

    this.service.post(this.mainObjects.S_CaseStudies_Img, "api/CaseStudies/addimgs", "").subscribe(
      (res) => {
       this.mainObjects.S_CaseStudies_Img=[];
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New IMG Saved :) ', 'Success');
      },
      (err) => { 

      })

}

uploadPDF()
{

  
    for (var i = 0; i < this.mypdfFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.mypdfFiles[i].name)).value;     
          this.mainObjects.S_CaseStudies_PDF[i].Title=inputValue;
    }


    this.service.post(this.mainObjects.S_CaseStudies_PDF, "api/CaseStudies/addpdf", "").subscribe(
      (res) => {
       this.mainObjects.S_CaseStudies_PDF=[];
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New PDF Saved :) ', 'Success');
      },
      (err) => { 

      })
}

uploadVideo()
{
 

    for (var i = 0; i < this.myvideoFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myvideoFiles[i].name)).value;     
          this.mainObjects.S_CaseStudies_Video[i].Title=inputValue;
    }

    this.service.post(this.mainObjects.S_CaseStudies_Video, "api/CaseStudies/addvideo", "").subscribe(
      (res) => {
       this.mainObjects.S_CaseStudies_Video=[];
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Video Saved :) ', 'Success');
      },
      (err) => { 

      })

}


updloadyoutubelinks()
{
  var inputValue = (<HTMLInputElement>document.getElementById("youtubelinks")).value;     
  if(inputValue.length>0)
  {
  var links=inputValue.split(",");
  for (var i = 0; i < links.length; i++) 
  { 
  this.mainObjects.S_CaseStudies_Video.push(new S_CaseStudies_Details(0,this.id,"",links[i]));
  }

  this.service.post(this.mainObjects.S_CaseStudies_Video, "api/CaseStudies/addvideo", "").subscribe(
    (res) => {
     this.mainObjects.S_CaseStudies_Video=[];
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success('New Video Saved :) ', 'Success');
    },
    (err) => { 

    })

  }



}



}

const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);

      
    };
    reader.onerror = reject;
  });
};
