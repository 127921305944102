import { Component, OnInit } from '@angular/core';
import { GameStatistics } from '@modules/maindata/Models/GameStatistics.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-game-statistics',
  templateUrl: './game-statistics.component.html',
  styleUrls: ['./game-statistics.component.scss']
})
export class GameStatisticsComponent implements OnInit {

  mainObject:GameStatistics;
  constructor(public service: publicService,private toastr: ToastrService) {

    this.mainObject=new GameStatistics();
    this.getGameStatistics();
   }
  ngOnInit(): void {
  }

  getGameStatistics()
  {
    this.service.getData("Api/Statistics/GetGameStatistics").subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        
      },
      error => {
      });

  }
}
