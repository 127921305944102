export class WorkShops {

    constructor() {
        this.Id=0;
        this.CategoryId=0;
        this.Title='';
        this.Date=new Date().toISOString().slice(0, 10);
        this.ShortDescription='';
        this.LongDescription='';
        this.ImageUrl='';
        this.TitleAr='';
        this.ShortDescriptionAr='';
        this.LongDescriptionAr='';
        this.S_WorkShops_Excel=[];
        this.S_WorkShops_Img=[];
        this.S_WorkShops_PDF=[];
        this.S_WorkShops_Video=[];
        this.S_WorkShops_Partners=[];
    }


    Id:number;
    Title:string;
    Date:string;
    ShortDescription:string;
    LongDescription:string;
    CategoryId:number;
    ImageUrl:string;
    TitleAr:string;
    ShortDescriptionAr:string;
    LongDescriptionAr:string;
    S_WorkShops_Excel:S_WorkShops_Details[];
    S_WorkShops_Img:S_WorkShops_Details[];
    S_WorkShops_PDF:S_WorkShops_Details[];
    S_WorkShops_Video:S_WorkShops_Details[];
    S_WorkShops_Partners:S_WorkShops_Partners[];
}

export class S_WorkShops_Details
{

    constructor(i:number,cs:number,T:string,U:string) {
        this.Id=i|0;
        this.WorkShopId=cs|0;
        this.Title=T;
        this.Url=U;
    }

Id:number;
WorkShopId:number;
Title:string;
Url:string;

}
export class S_WorkShops_Partners
{
    constructor(i:number,cs:number,T:string,U:string,D:string) {
        this.Id=i|0;
        this.WorkShopId=cs|0;
        this.Name=T;
        this.Url=U;
        this.Description=D;
    }

Id:number;
WorkShopId:number;
Description:string;
Name:string;
Url:string;
}
