import { SideNavItems, SideNavSection } from '@modules/navigation/models';

export const sideNavSections: SideNavSection[] = [
    {
        text: 'Home',
        items: ['dashboard','homeslider','contactus','news'],
    },
    {
        text: 'HUB',
        items: ['CaseStudies','CaseStudiesExperiments','CaseStudiesCharts','DissemenationMaterial','DocumentsRepository','Links','BusinessModelCanvas','ValuePropositionCanvas'],
    },
    {
        text: 'Media Center',
        items: ['Workshops','Webinars','Albums'],
    },
    {
        text: 'Community',
        items: ['Topics', 'Users','Statistics'],
    },
    {
        text: 'Game',
        items: ['GameStatistics', 'GameUsers','Items','GameCropsInfo','InfoandInstructions'],
    },
];

export const sideNavItems: SideNavItems = {
   
    homeslider:
   {
    icon: 'tachometer-alt',
    text: 'Home Slider',
    link: '/home/homeslider',
   },
   contactus:
   {
    icon: 'tachometer-alt',
    text: 'contact us',
    link: '/home/contactus',
   },
   news:
   {
    icon: 'tachometer-alt',
    text: 'News',
    link: '/home/news',
   },
    dashboard: {
        icon: 'tachometer-alt',
        text: 'Dashboard',
        link: '/dashboard',
        
    },
    CaseStudies: {
        icon: 'tachometer-alt',
        text: 'Case Studies',
        link: '/Hub/CaseStudies',
        
    },
    CaseStudiesExperiments:
    {
        icon: 'tachometer-alt',
        text: 'Case Studies Experiments',
        link: '/Hub/CaseStudiesExperiments',
    },
    CaseStudiesCharts:{
        icon: 'tachometer-alt',
        text: 'Case Studies Charts',
        link: '/Hub/CaseStudiesCharts',
    },
    DissemenationMaterial: {
        icon: 'tachometer-alt',
        text: 'Dissemenation Material',
        link: '/Hub/DissemenationMaterial',
        
    },
    DocumentsRepository: {
        icon: 'tachometer-alt',
        text: 'Documents Repository',
        link: '/Hub/DocumentsRepository',
    },
    Links: {
        icon: 'tachometer-alt',
        text: 'Links',
        link: '/Hub/Links',
    },
    BusinessModelCanvas:
    {
        icon: 'tachometer-alt',
        text: 'Business Model Canvas',
        link: '/Hub/BusinessModelCanvas',
    },
    ValuePropositionCanvas:
    {
        icon: 'tachometer-alt',
        text: 'Value Proposition Canvas',
        link: '/Hub/ValuePropositionCanvas',
    },
    Workshops: {
        icon: 'tachometer-alt',
        text: 'Workshops',
        link: '/MediaCenter/Workshops',
    },     
    Webinars: {
        icon: 'tachometer-alt',
        text: 'Webinars',
        link: '/MediaCenter/Webinars',
    },  
    Albums: {
        icon: 'tachometer-alt',
        text: 'Albums',
        link: '/MediaCenter/Albums',
    },  
    Topics: {
        icon: 'tachometer-alt',
        text: 'Topics',
        link: '/Community/Topics',
    },  
    Users: {
        icon: 'tachometer-alt',
        text: 'Users',
        link: '/Community/Users',
    }, 
    Statistics: {
        icon: 'tachometer-alt',
        text: 'Statistics',
        link: '/Community/Statistics',
    }, 
    GameUsers: {
        icon: 'tachometer-alt',
        text: 'Game Users',
        link: '/Game/GameUsers',
    },
    GameStatistics: {
        icon: 'tachometer-alt',
        text: 'Game Statistics',
        link: '/Game/GameStatistics',
    },
    Items: {
        icon: 'tachometer-alt',
        text: 'Game Items',
        link: '/Game/GameItems',
    },
    GameCropsInfo:{
        icon: 'tachometer-alt',
        text: 'Crops & Trees Info',
        link: '/Game/CropsInfo',
    }
    ,
    InfoandInstructions:{
        icon: 'tachometer-alt',
        text: 'Info and Instructions',
        link: '/Game/InfoandInstructions',
    }
};
