import { Component, OnInit } from '@angular/core';
import { VPC } from '@modules/maindata/Models/VPC.model';
import { environment } from 'Sheard/environment';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-value-proposition-canvas',
  templateUrl: './value-proposition-canvas.component.html',
  styleUrls: ['./value-proposition-canvas.component.scss']
})
export class ValuePropositionCanvasComponent implements OnInit {
  mainObject:VPC;
  VPCs:VPC[]=[];
  id:number=0;

  constructor(public service: publicService) {

    this.mainObject=new VPC();
   }

  ngOnInit(): void {
    this.GetVPCs();
  }

  onChange(event:any)
  {
    this.id=event.target.value;

    this.service.getData("Api/VPC/"+this.id, "").subscribe(
      (res) => {
        this.mainObject=res;
      },
      (err) => { 
  
      })

  }
  GetVPCs()
  {
    this.service.getData("Api/VPC/GetAll", "").subscribe(
      (res) => {
        this.VPCs=res;
      },
      (err) => { 
  
      })
  }

  Next()
  {
    this.service.getData("api/VPC/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
      
      },
      error => {
      });
  }
  Previous()
  {
 
    this.service.getData("api/VPC/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
  
      },
      error => {
      });
  }

  print()
  {
    this.service.get("Api/VPC/print/"+this.mainObject.Id, "","").subscribe(
      (rep) => {

        window.location.href = environment.serverUrl+""+rep.pdfUrl;
      

      },
      (err) => { 
  
      })
  }

}
