import { Component, OnInit } from '@angular/core';
import { S_Webinars_Details, S_Webinars_Partners, Webinars } from '@modules/maindata/Models/Webinars.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {

  mainObject: Webinars;
  IDS:string='';
  myexcelFiles:File [] = [];
  myvideoFiles:File [] = [];
  mypdfFiles:File [] = [];
  myimageFiles:File [] = [];
  mypartnersimageFiles:File [] = [];


  constructor(public service: publicService,private toastr: ToastrService) 
  { 
      this.mainObject = new Webinars();
  }


  
  ngOnInit(): void {

    //this.loadEmployes();
    //this.loadHealthConditions();
    //this.loadSecurityLevels();

  }
  onExcelFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_Webinars_Excel.push(new S_Webinars_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myexcelFiles.push(event.target.files[i]);
  }
  
  }
  onVideoFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_Webinars_Video.push(new S_Webinars_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.myvideoFiles.push(event.target.files[i]);
  }
 
  }
  onPDFFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_Webinars_PDF.push(new S_Webinars_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.mypdfFiles.push(event.target.files[i]);
  }
  
  }

  onPartnersImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_Webinars_Partners.push(new S_Webinars_Partners(0,0,"",String(ev.target.result),""));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.mypartnersimageFiles.push(event.target.files[i]);
  }
    
  }

  onImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_Webinars_Img.push(new S_Webinars_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.myimageFiles.push(event.target.files[i]);
  }
    
  }
  onMainImageChoose(mainimag:any)
  {
    //upload file and get new link 
    console.log(mainimag.target.files[0]);

    var reader = new FileReader();
    reader.onload = (ev:any) => {
        this.mainObject.ImageUrl=String(ev.target.result);
    }
    reader.readAsDataURL(mainimag.target.files[0]);

  }

  Save()
  {
    for (var i = 0; i < this.mypartnersimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.mypartnersimageFiles[i].name)).value; 
           var inputValueD = (<HTMLInputElement>document.getElementById(this.mypartnersimageFiles[i].name+"_D")).value;     
           this.mainObject.S_Webinars_Partners[i].Name=inputValue;
           this.mainObject.S_Webinars_Partners[i].Description=inputValueD;
    }
    for (var i = 0; i < this.myimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.myimageFiles[i].name)).value;     
           this.mainObject.S_Webinars_Img[i].Title=inputValue;
    }

    for (var i = 0; i < this.myvideoFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myvideoFiles[i].name)).value;     
          this.mainObject.S_Webinars_Video[i].Title=inputValue;
    }

    for (var i = 0; i < this.myexcelFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myexcelFiles[i].name)).value;     
          this.mainObject.S_Webinars_Excel[i].Title=inputValue;
    }
    for (var i = 0; i < this.mypdfFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.mypdfFiles[i].name)).value;     
          this.mainObject.S_Webinars_PDF[i].Title=inputValue;
    }

    this.service.post(this.mainObject, "Api/Webinars", "").subscribe(
      (res) => {
        this.mainObject=new Webinars();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Webinar Saved :) ', 'Success');
      },
      (err) => { 

      })
  }
  Update()
  {

    this.service.post(this.mainObject, "Api/Webinars/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Webinars Updated :) ', 'Success');
      },
      (err) => { 

      })


  }




  Clear()
  {
    this.mainObject=new Webinars();
  }


  Delete()
  {
    this.service.get("api/Webinars/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new Webinars();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Webinar Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  
  Next()
  {
    this.service.getData("api/Webinars/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }

  Previous()
  {
 
    this.service.getData("api/Webinars/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }


}

