import { Component, OnInit } from '@angular/core';
import { DocumentsRepository } from '@modules/maindata/Models/DocumentsRepository.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-documents-repository',
  templateUrl: './documents-repository.component.html',
  styleUrls: ['./documents-repository.component.scss']
})
export class DocumentsRepositoryComponent implements OnInit {

  mainObject: DocumentsRepository;

  constructor(public service: publicService,private toastr: ToastrService) { 
    this.mainObject = new DocumentsRepository();
  }

  ngOnInit(): void {
  }

  Save()
  {
    this.service.post(this.mainObject, "Api/DocumentsRepo", "").subscribe(
      (res) => {
        this.mainObject=new DocumentsRepository();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Documents Saved :) ', 'Success');
      },
      (err) => { 

      })
  }

  Clear()
  {
    this.mainObject=new DocumentsRepository();
  }



  onChange(mainimag:any)
  {
    //upload file and get new link 
    var reader = new FileReader();
    reader.onload = (ev:any) => {
       this.mainObject.Url= String(ev.target.result);
       //console.log(this.mainObject.Url);
      }

    reader.readAsDataURL(mainimag.target.files[0]);
  }

  Update()
  {
   
    this.service.post(this.mainObject, "Api/DocumentsRepo/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Document Updated :) ', 'Success');
      },
      (err) => { 

      })

    }
  Delete()
  {
    this.service.get("api/DocumentsRepo/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new DocumentsRepository();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success(' Case Study Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  Next()
  {
    this.service.getData("api/DocumentsRepo/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.CreatedDate = this.mainObject.CreatedDate.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }
  Previous()
  {
 
    this.service.getData("api/DocumentsRepo/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.CreatedDate = this.mainObject.CreatedDate.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }

}
