export class Webinars {

    constructor() {
        this.Id=0;
        this.CategoryId=0;
        this.Title='';
        this.Date=new Date().toISOString().slice(0, 10);
        this.ShortDescription='';
        this.LongDescription='';
        this.ImageUrl='';
        this.TitleAr='';
        this.ShortDescriptionAr='';
        this.LongDescriptionAr='';
        this.S_Webinars_Excel=[];
        this.S_Webinars_Img=[];
        this.S_Webinars_PDF=[];
        this.S_Webinars_Video=[];
        this.S_Webinars_Partners=[];
    }


    Id:number;
    Title:string;
    Date:string;
    ShortDescription:string;
    LongDescription:string;
    CategoryId:number;
    ImageUrl:string;
    TitleAr:string;
    ShortDescriptionAr:string;
    LongDescriptionAr:string;
    S_Webinars_Excel:S_Webinars_Details[];
    S_Webinars_Img:S_Webinars_Details[];
    S_Webinars_PDF:S_Webinars_Details[];
    S_Webinars_Video:S_Webinars_Details[];
    S_Webinars_Partners:S_Webinars_Partners[];
}

export class S_Webinars_Details
{
    constructor(i:number,cs:number,T:string,U:string) {
        this.Id=i|0;
        this.WebinarsId=cs|0;
        this.Title=T;
        this.Url=U;
    }
Id:number;
WebinarsId:number;
Title:string;
Url:string;

}
export class S_Webinars_Partners
{
    constructor(i:number,cs:number,T:string,U:string,D:string) {
        this.Id=i|0;
        this.WebinarsId=cs|0;
        this.Name=T;
        this.Url=U;
        this.Description=D;
    }

Id:number;
WebinarsId:number;
Description:string;
Name:string;
Url:string;
}
