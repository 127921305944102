import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { NavigationModule } from '@modules/navigation/navigation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CasestudiesComponent } from '@modules/maindata/components/Hub/CaseStudies/casestudies.component';
import { DissemenationMaterialComponent } from '../modules/maindata/components/Hub/dissemenation-material/dissemenation-material.component';
import { DocumentsRepositoryComponent } from '../modules/maindata/components/Hub/documents-repository/documents-repository.component';
import { WebinarsComponent } from '../modules/maindata/components/MediaCenter/webinars/webinars.component';
import { WorkshopsComponent } from '@modules/maindata/components/MediaCenter/workshops/workshops.component';
import { AlbumsComponent } from '../modules/maindata/components/MediaCenter/albums/albums.component';
import { TopicsComponent } from '../modules/maindata/components/COMMUNITY/topics/topics.component';
import { ForumusersComponent } from '../modules/maindata/components/COMMUNITY/forumusers/forumusers.component';
import { ForumStatisticsComponent } from '../modules/maindata/components/COMMUNITY/forum-statistics/forum-statistics.component';
import { GameUsersComponent } from '@modules/maindata/components/Game/game-users/game-users.component';
import { GameStatisticsComponent } from '@modules/maindata/components/Game/game-statistics/game-statistics.component';
import { CaseStudiesExperimentsComponent } from '../modules/maindata/components/Hub/case-studies-experiments/case-studies-experiments.component';
import { CaseStudiesChartsComponent } from '@modules/maindata/components/Hub/case-studies-charts/case-studies-charts.component';
import { LinksComponent } from '../modules/maindata/components/Hub/links/links.component';
import { GameItemsComponent } from '../modules/maindata/components/Game/game-items/game-items.component';
import { HomeSliderComponent } from '../modules/maindata/components/Home/home-slider/home-slider.component';
import { ContactUsComponent } from '../modules/maindata/components/Home/contact-us/contact-us.component';
import { NewsComponent } from '../modules/maindata/components/Home/news/news.component';
import { QuillModule } from 'ngx-quill';
import { CropInfoComponent } from '@modules/maindata/components/Game/crop-info/crop-info.component';
import { InfoandInstructionsComponent } from '../modules/maindata/components/Game/infoand-instructions/infoand-instructions.component';
import { ValuePropositionCanvasComponent } from '../modules/maindata/components/Hub/value-proposition-canvas/value-proposition-canvas.component';
import { BusinessModelCanvasComponent } from '../modules/maindata/components/Hub/business-model-canvas/business-model-canvas.component';
import { CaseStudyEditComponent } from '../modules/maindata/components/Hub/case-study-edit/case-study-edit.component';




@NgModule({
    declarations: [
        AppComponent,
        CasestudiesComponent,
        DissemenationMaterialComponent,
        DocumentsRepositoryComponent,
        WorkshopsComponent,
        WebinarsComponent,
        AlbumsComponent,
        TopicsComponent,
        ForumusersComponent,
        ForumStatisticsComponent,
        GameUsersComponent,
        GameStatisticsComponent,
        CaseStudiesExperimentsComponent,
        CaseStudiesChartsComponent,
        LinksComponent,
        GameItemsComponent,
        HomeSliderComponent,
        ContactUsComponent,
        NewsComponent,
        CropInfoComponent,
        InfoandInstructionsComponent,
        ValuePropositionCanvasComponent,
        BusinessModelCanvasComponent,
        CaseStudyEditComponent,
        
    ],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule, NavigationModule,FormsModule,
        ToastrModule.forRoot({
            timeOut:3000,
            progressBar:true,
            progressAnimation:'increasing',
            preventDuplicates:true,
        }), // ToastrModule added
        BrowserAnimationsModule,
       
        QuillModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
