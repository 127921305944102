import { Component, OnInit } from '@angular/core';
import { BMC } from '@modules/maindata/Models/BMC.model';
import { environment } from 'Sheard/environment';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-business-model-canvas',
  templateUrl: './business-model-canvas.component.html',
  styleUrls: ['./business-model-canvas.component.scss']
})
export class BusinessModelCanvasComponent implements OnInit {
  mainObject:BMC;
  BMCs:BMC[]=[];
  id:number=0;
  constructor(public service: publicService) 
  {
    this.mainObject=new BMC();
   }

   ngOnInit(): void {
    this.GetBMCs();
  }

  onChange(event:any)
  {
    this.id=event.target.value;

    this.service.getData("Api/BMC/"+this.id, "").subscribe(
      (res) => {
        this.mainObject=res;
      },
      (err) => { 
  
      })

  }
  GetBMCs()
  {
    this.service.getData("Api/BMC/GetAll", "").subscribe(
      (res) => {
        this.BMCs=res;
      },
      (err) => { 
  
      })
  }

  Next()
  {
    this.service.getData("api/BMC/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
      
      },
      error => {
      });
  }
  Previous()
  {
 
    this.service.getData("api/BMC/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
  
      },
      error => {
      });
  }
  print()
  {
    this.service.get("Api/BMC/print/"+this.mainObject.Id, "","").subscribe(
      (rep) => {

        window.location.href = environment.serverUrl+""+rep.pdfUrl;
      

      },
      (err) => { 
  
      })
  }


}
