export class CropInfo 
{

    constructor() {
        this.Id=0;
        this.ItemId=0;
        this.YieldFrom=0;
        this.YieldTo=0;
        this.IrrigationTypeId=0;
        this.IrrigationNo=0;
        this.RipeningPeriod=0;
        this.HarvestNo=0;
        this.SeedPrice=0;;
        this.ItemName='';
        this.SeasonId=0;
       
    }



    Id:number;
    ItemId:number;
    ItemName:string;
    SeasonId:number;
    YieldFrom:number;
    YieldTo:number;
    IrrigationTypeId:number;
    IrrigationNo:number;
    RipeningPeriod:number;
    HarvestNo:number;
    SeedPrice:number;
}