export class CaseStudy {

    constructor() {
        this.Id=0;
        this.CategoryId="0";
        this.Title='';
        this.Date=new Date().toISOString().slice(0, 10);
        this.ShortDescription='';
        this.LongDescription='';
        this.ImageUrl='';
        this.TitleAr='';
        this.Type=0;
        this.ShortDescriptionAr='';
        this.LongDescriptionAr='';
        this.S_CaseStudies_Excel=[];
        this.S_CaseStudies_Img=[];
        this.S_CaseStudies_PDF=[];
        this.S_CaseStudies_Video=[];
        this.S_CaseStudies_Conflicts=[];
        this.S_CaseStudies_Relations=[];
        this.S_CaseStudiesExperiments=[];
    }


    Id:number;
    Title:string;
    Date:string;
    ShortDescription:string;
    LongDescription:string;
    CategoryId:string;
    ImageUrl:string;
    TitleAr:string;
    ShortDescriptionAr:string;
    LongDescriptionAr:string;
    Type:number;
    S_CaseStudies_Excel:S_CaseStudies_Details[];
    S_CaseStudies_Img:S_CaseStudies_Details[];
    S_CaseStudies_PDF:S_CaseStudies_Details[];
    S_CaseStudies_Video:S_CaseStudies_Details[];
    S_CaseStudies_Relations:S_CaseStudies_RandC[];
    S_CaseStudies_Conflicts:S_CaseStudies_RandC[];
    S_CaseStudiesExperiments:S_CaseStudiesExperiments[]; 

}

export class S_CaseStudies_Details
{
    constructor(i:number,cs:number,T:string,U:string) {
        this.Id=i|0;
        this.CaseStudyId=cs|0;
        this.Title=T;
        this.Url=U;
    }
Id:number;
CaseStudyId:number;
Title:string;
Url:string;

}

export class S_CaseStudies_RandC
{
    constructor(i:number,cs:number,T:string,U:string) {
        this.Id=i|0;
        this.CaseStudyId=cs|0;
        this.Description=T;
        this.ImageUrl=U;
    }
Id:number;
CaseStudyId:number;
Description:string;
ImageUrl:string;

}

export class S_CaseStudiesExperiments 
{
    constructor() {
        this.Id=0;
        this.CaseStudyId=0;
        this.Description="";
        this.Name="";
        this.S_CaseStudiesExperiments_Img=[];
        this.S_CaseStudiesExperiments_Video=[];
        this.S_CaseStudiesExperimentsFiles=[];
    }
    Id:number;
    CaseStudyId:number;
    Description:string;
    Name:string;
    S_CaseStudiesExperiments_Img:S_CaseStudiesExperiments_Details[];
    S_CaseStudiesExperiments_Video:S_CaseStudiesExperiments_Details[];
    S_CaseStudiesExperimentsFiles:S_CaseStudiesExperimentsFiles[];
}

export class S_CaseStudiesExperiments_Details
{
    constructor(i:number,cs:number,T:string,U:string) {
        this.Id=i|0;
        this.ExperimentsId=cs|0;
        this.Title=T;
        this.Url=U;
    }
Id:number;
ExperimentsId:number;
Title:string;
Url:string;

}
export class S_CaseStudiesExperimentsFiles
{
    constructor(i:number,cs:number,T:string,U:string,ft:number) {
        this.Id=i|0;
        this.ExperimentsId=cs|0;
        this.FiletypeId=ft|0;
        this.Title=T;
        this.Url=U;
    }
Id:number;
ExperimentsId:number;
Title:string;
Url:string;
FiletypeId:number;
}


export class CaseStudiesCharts
{
    constructor()
    {
        this.Id=0;
        this.CaseStudyId=0;
        this.Name="";
        this.Type=0;
        this.Year=0;
        this.File="";
    }
Id:number;
Year:number;
Name:string;
Type:number;
CaseStudyId:number;
File:string;

}


export class CaseStudyRes {
  
    constructor() {
        this.Id=0;
        this.CategoryId="0";
        this.Title='';
        this.Date=new Date().toISOString().slice(0, 10);
        this.ShortDescription='';
        this.LongDescription='';
        this.ImageUrl='';
        this.TitleAr='';
        this.Type=0;
        this.ShortDescriptionAr='';
        this.LongDescriptionAr='';
        this.S_CaseStudies_Excel=[];
        this.S_CaseStudies_Img=[];
        this.S_CaseStudies_PDF=[];
        this.S_CaseStudies_Video=[];
      
        this.S_CaseStudiesExperiments=[];
    }


    Id:number;
    Title:string;
    Date:string;
    ShortDescription:string;
    LongDescription:string;
    CategoryId:string;
    ImageUrl:string;
    TitleAr:string;
    ShortDescriptionAr:string;
    LongDescriptionAr:string;
    Type:number;
    S_CaseStudies_Excel:S_CaseStudies_Details[];
    S_CaseStudies_Img:S_CaseStudies_Details[];
    S_CaseStudies_PDF:S_CaseStudies_Details[];
    S_CaseStudies_Video:S_CaseStudies_Details[];
    S_CaseStudies_Relations?:S_CaseStudies_RandC;
    S_CaseStudies_Conflicts?:S_CaseStudies_RandC;
    S_CaseStudiesExperiments:S_CaseStudiesExperiments[]; 


}