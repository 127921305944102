export class Links
{
    constructor() {
        this.Id=0;
        this.Name="";
        this.Url="";
        this.Description="";
        this.logo="";
    }

Id:number;
Description:string;
Name:string;
Url:string;
logo:string;
}