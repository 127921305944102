import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { User } from '../models';

const userSubject: ReplaySubject<User> = new ReplaySubject(1);

@Injectable()
export class UserService {
    constructor() {

        var values = JSON.parse(localStorage.getItem("userinfo")||"");

        this.user = {
            id: values.Id,
            firstName: values.FirstName,
            lastName: values.LastName,
            email: values.Email,
        };
    }

    set user(user: User) {
        userSubject.next(user);
    }

    get user$(): Observable<User> {
        return userSubject.asObservable();
    }
}
