import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { CaseStudy, S_CaseStudies_Details, S_CaseStudies_RandC } from '@modules/maindata/Models/CaseStudy.model';
import { img } from '@modules/maindata/Models/img.model';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from 'Sheard/file-upload.service';
import { publicService } from 'Sheard/public-service.service';


@Component({
  selector: 'sb-casestudies',
  templateUrl: './casestudies.component.html',
  styleUrls: ['./casestudies.component.scss']
})
export class CasestudiesComponent implements OnInit {

  mainObject: CaseStudy;
  IDS:string='';
  myexcelFiles:File [] = [];
  myvideoFiles:File [] = [];
  mypdfFiles:File [] = [];
  myimageFiles:File [] = [];
  CategoryIds:string[]=[];
  url:string="";
  relationimg:string="";
  Conflictimg:string="";
  id:any;
  CaseStudyRelation:string="";
  CaseStudyConflict:string="";

  CaseStudies:CaseStudy[];

  constructor(private router: Router,public service: publicService,private toastr: ToastrService,public fileUpload:FileUploadService) 
  { 
      this.CaseStudies = [];
      this.mainObject = new CaseStudy();
  }


  
  ngOnInit(): void {

    //this.loadEmployes();
    //this.loadHealthConditions();
    //this.loadSecurityLevels();
    this.GetCaseStudies();
  }

  GoToUpdate()
  {
    this.router.navigate(['/Hub/Case-Studies-Details/'+this.id]);
  }
  
  onChange(event:any)
  {
    this.id=event.target.value;
  }

 

  onExcelFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_CaseStudies_Excel.push(new S_CaseStudies_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myexcelFiles.push(event.target.files[i]);
  }
  console.log(this.myexcelFiles);
  }
  onVideoFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_CaseStudies_Video.push(new S_CaseStudies_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myvideoFiles.push(event.target.files[i]);
  }
  console.log(this.myvideoFiles);
  }

  onPDFFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_CaseStudies_PDF.push(new S_CaseStudies_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.mypdfFiles.push(event.target.files[i]);
  }
  console.log(this.mypdfFiles);
  }
  
  onImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_CaseStudies_Img.push(new S_CaseStudies_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);


      this.myimageFiles.push(event.target.files[i]);
  }
     
  }


onMainImageChoose(mainimag:any)
  {
    if (mainimag.target.files && mainimag.target.files[0]) {
     
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.url=event.target.result;
          this.mainObject.ImageUrl=event.target.result;
      }
      reader.readAsDataURL(mainimag.target.files[0]);
      //this.onUpload(mainimag.target.files[0]);
  }
}
onRelationImageChoose(mainimag:any)
{
  if (mainimag.target.files && mainimag.target.files[0]) {
   
    var reader = new FileReader();
    reader.onload = (event:any) => {
        this.relationimg=event.target.result;
        
    }
    reader.readAsDataURL(mainimag.target.files[0]);
    //this.onUpload(mainimag.target.files[0]);
}
}
onConflictImageChoose(mainimag:any)
{
  if (mainimag.target.files && mainimag.target.files[0]) {
   
    var reader = new FileReader();
    reader.onload = (event:any) => {
        this.Conflictimg=event.target.result;
    }
    reader.readAsDataURL(mainimag.target.files[0]);
    //this.onUpload(mainimag.target.files[0]);
}
}

GetCaseStudies()
{
  this.service.getData("Api/CaseStudies/getAllCaseStudies", "").subscribe(
    (res) => {
      this.CaseStudies=res;
    },
    (err) => { 

    })
}
  

  Save()
  {
    var cat="";
    this.CategoryIds.forEach(element => {
    cat+=element+",";
    });
 
    this.mainObject.CategoryId=cat;

    debugger;
    var inputValue = (<HTMLInputElement>document.getElementById("youtubelinks")).value;     
    if(inputValue.length>0)
    {
    var links=inputValue.split(",");
    for (var i = 0; i < links.length; i++) 
    { 
    this.mainObject.S_CaseStudies_Video.push(new S_CaseStudies_Details(0,0,"",links[i]));
    }
    }

    for (var i = 0; i < this.myimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.myimageFiles[i].name)).value;     
           this.mainObject.S_CaseStudies_Img[i].Title=inputValue;
    }

    for (var i = 0; i < this.myvideoFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myvideoFiles[i].name)).value;     
          this.mainObject.S_CaseStudies_Video[i].Title=inputValue;
    }

    for (var i = 0; i < this.myexcelFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myexcelFiles[i].name)).value;     
          this.mainObject.S_CaseStudies_Excel[i].Title=inputValue;
    }
    for (var i = 0; i < this.mypdfFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.mypdfFiles[i].name)).value;     
          this.mainObject.S_CaseStudies_PDF[i].Title=inputValue;
    }

    this.mainObject.S_CaseStudies_Conflicts.push(new S_CaseStudies_RandC(0,0,this.CaseStudyConflict,this.Conflictimg));
    this.mainObject.S_CaseStudies_Relations.push(new S_CaseStudies_RandC(0,0,this.CaseStudyRelation,this.relationimg));

    this.service.post(this.mainObject, "Api/CaseStudies", "").subscribe(
      (res) => {
        this.mainObject=new CaseStudy();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Case Study Saved :) ', 'Success');
      },
      (err) => { 

      })
  }


  Delete()
  {
    this.service.get("api/CaseStudies/Delete/"+this.id, "","").subscribe(
      (res) => {
       this.mainObject=new CaseStudy();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success(' Case Study Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }

  
  // Next()
  // {
  //   this.service.getData("api/CaseStudies/GetNextRow/"+this.mainObject.Id).subscribe(
  //     data => {
  //       if(data!=null)
  //       this.mainObject = data;
  //       this.mainObject.Date = this.mainObject.Date.slice(0, 10);
  //       debugger;
  //       this.CategoryIds=this.mainObject.CategoryId.slice(0, -1).split(",");
  //       //console.log(data);
  //     },
  //     error => {
  //     });
  // }
  // Previous()
  // {
 
  //   this.service.getData("api/CaseStudies/GetPreviousRow/"+this.mainObject.Id).subscribe(
  //     data => {
  //       if(data!=null)
  //       this.mainObject = data;
  //       this.mainObject.Date = this.mainObject.Date.slice(0, 10);
  //       //console.log(data);
  //       debugger;
  //       this.CategoryIds=this.mainObject.CategoryId.slice(0, -1).split(",");
  //     },
  //     error => {
  //     });
  // }

  Clear()
  {
    this.mainObject=new CaseStudy();
  }

  onUpload(file:File) {
 
    this.fileUpload.upload(file,file.name).subscribe(
        (event: any) => {
          
             debugger;
             console.log(event);
              this.mainObject.ImageUrl="/Uploads/Images/"+event;
              console.log(this.mainObject.ImageUrl);
            
        }
    );
}

  getBase64(file:File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

}


