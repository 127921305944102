import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '@modules/auth/services';

@Component({
    selector: 'sb-top-nav-user',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent implements OnInit {
    constructor(public userService: UserService,private router : Router) {}
    ngOnInit() {}

logout()
{
    debugger;
    localStorage.removeItem('userinfo');
    localStorage.removeItem('EstablishmentId');
    localStorage.removeItem('usertype'); 

    this.router.navigate(['/']);
}

}

