import { Component, OnInit } from '@angular/core';
import { ForumStatistics } from '@modules/maindata/Models/ForumStatistics.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-forum-statistics',
  templateUrl: './forum-statistics.component.html',
  styleUrls: ['./forum-statistics.component.scss']
})
export class ForumStatisticsComponent implements OnInit {

  mainObject:ForumStatistics;
  constructor(public service: publicService,private toastr: ToastrService) {

    this.mainObject=new ForumStatistics();
    this.getForumStatistics();
   }

  ngOnInit(): void {
  }
  getForumStatistics()
  {
    this.service.getData("Api/Statistics/GetForumStatistics").subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        console.log(data);
      },
      error => {
      });

  }
}
