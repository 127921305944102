import { Component, OnInit } from '@angular/core';
import { DissemenationMaterial } from '@modules/maindata/Models/DissemenationMaterial.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-dissemenation-material',
  templateUrl: './dissemenation-material.component.html',
  styleUrls: ['./dissemenation-material.component.scss']
})
export class DissemenationMaterialComponent implements OnInit {

  url:string="";
  mainObject: DissemenationMaterial;

  constructor(public service: publicService,private toastr: ToastrService) { 
    this.mainObject = new DissemenationMaterial();
  }

  ngOnInit(): void {
  }

  Save()
  {
 debugger;
    var inputValue = (<HTMLInputElement>document.getElementById("youtubelinks")).value;     
    if(inputValue.length>0)
    {
      this.mainObject.Url=inputValue;
    }


    //console.log(this.mainObject);

    this.service.post(this.mainObject, "Api/DissemenationMaterial", "").subscribe(
      (res) => {
        this.mainObject=new DissemenationMaterial();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Dissemenation Material Saved :) ', 'Success');
      },
      (err) => { 

      })
  
  
  }

  Clear()
  {
    this.mainObject=new DissemenationMaterial();
  }



  onChange(mainimag:any)
  {
    //upload file and get new link 
    //console.log(mainimag.target.files[0]);

    var reader = new FileReader();
    reader.onload = (ev:any) => {
       this.mainObject.Url= String(ev.target.result);
       //console.log(this.mainObject.Url);
      }

    reader.readAsDataURL(mainimag.target.files[0]);

  }

  Update()
  {
   
    this.service.post(this.mainObject, "Api/DissemenationMaterial/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Dissemenation Material Updated :) ', 'Success');
      },
      (err) => { 

      })

    }

  Delete()
  {
    this.service.get("api/DissemenationMaterial/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new DissemenationMaterial();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success(' Case Study Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  Next()
  {
    this.service.getData("api/DissemenationMaterial/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.CreatedDate = this.mainObject.CreatedDate.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }
  Previous()
  {
 
    this.service.getData("api/DissemenationMaterial/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.CreatedDate = this.mainObject.CreatedDate.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }


}
