import { Component, OnInit } from '@angular/core';
import { ContactUs } from '@modules/maindata/Models/ContactUs.model';
import { publicService } from 'Sheard/public-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sb-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  inbox:ContactUs[]=[];
  constructor(public service: publicService,private toastr: ToastrService)
   {
   }

  ngOnInit(): void {
    this.Getinbox();
  }
  
  Getinbox()
  {
    this.service.getData("Api/ContactUs/getAllContactUs", "").subscribe(
      (res) => {
        this.inbox=res;
       
        
      },
      (err) => { 

      })
  }
}
