export class InfoandInstructions
{
    constructor() 
    {
        this.Id=0;
        this.Compost_Info='';
        this.Nitrogen_Fertilizer_Info='';
        this.Phosphate_Fertilizer_Info='';
        this.Potassium_Fertilizer_Info='';
        this.Fungicide_Info='';
        this.Herbicide_Info='';
        this.Insecticide_Info='';
        this.Biocides_Info='';
        this.Government_Instructions='';
        this.Meteorological_Instructions='';
    }

    Id: number
    Compost_Info: string
    Nitrogen_Fertilizer_Info: string
    Phosphate_Fertilizer_Info: string
    Potassium_Fertilizer_Info: string
    Fungicide_Info: string
    Herbicide_Info: string
    Insecticide_Info: string
    Biocides_Info: string
    Government_Instructions: string
    Meteorological_Instructions: string
}