import { Component, OnInit } from '@angular/core';
import { Links } from '@modules/maindata/Models/Links.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

  mainObject:Links;
  url:string="";
  constructor(public service: publicService,private toastr: ToastrService) { 

    this.mainObject=new Links();

  }

  ngOnInit(): void {
  }
  onMainImageChoose(mainimag:any)
  {
    if (mainimag.target.files && mainimag.target.files[0]) {
     
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.url=event.target.result;
          this.mainObject.logo=event.target.result;
      }
      reader.readAsDataURL(mainimag.target.files[0]);
     
  }
}

Save()
{
  this.service.post(this.mainObject, "Api/Links", "").subscribe(
    (res) => {
      this.mainObject=new Links();
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success('New Link Saved :) ', 'Success');
    },
    (err) => { 

    })
}

Update()
{
 



}
Delete()
{
  this.service.get("api/Links/Delete/"+this.mainObject.Id, "","").subscribe(
    (res) => {
     this.mainObject=new Links();
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success(' Case Study Deleted :) ', 'Success');
    },
    (err) => { 

    })
}
Next()
{
  this.service.getData("api/Links/GetNextRow/"+this.mainObject.Id).subscribe(
    data => {
      if(data!=null)
      this.mainObject = data;
      
      //console.log(data);
    },
    error => {
    });
}
Previous()
{

  this.service.getData("api/Links/GetPreviousRow/"+this.mainObject.Id).subscribe(
    data => {
      if(data!=null)
      this.mainObject = data;
      
      //console.log(data);
    },
    error => {
    });
}

}
