import { Component, OnInit } from '@angular/core';
import { Albums, Gallary_Details } from '@modules/maindata/Models/Albums.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent implements OnInit {

  mainObject: Albums;
  IDS:string='';
  myvideoFiles:File [] = [];
  myimageFiles:File [] = [];


  constructor(public service: publicService,private toastr: ToastrService) 
  { 
      this.mainObject = new Albums();
  }

  
  ngOnInit(): void {

  }

  onVideoFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) 
    { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_VideoGallary.push(new Gallary_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myvideoFiles.push(event.target.files[i]);
    }


  //console.log(this.myvideoFiles);
  } 
  onImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_ImagesGallary.push(new Gallary_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myimageFiles.push(event.target.files[i]);
  
    }
     //console.log(this.myimageFiles);
  }
  onMainImageChoose(mainimag:any)
  {
    //upload file and get new link 
    console.log(mainimag.target.files[0]);
  }
  

  Save()
  {
    for (var i = 0; i < this.myimageFiles.length; i++) 
    { 
    
           var inputValue = (<HTMLInputElement>document.getElementById(this.myimageFiles[i].name)).value;     
           this.mainObject.S_ImagesGallary[i].Title=inputValue;
    }

    for (var i = 0; i < this.myvideoFiles.length; i++) 
    { 
    
          var inputValue = (<HTMLInputElement>document.getElementById(this.myvideoFiles[i].name)).value;     
          this.mainObject.S_VideoGallary[i].Title=inputValue;
    }
   
    console.log(this.mainObject);

     this.service.post(this.mainObject, "Api/Gallary", "").subscribe(
       (res) => {
        this.mainObject=new Albums();
        this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
        this.toastr.success('New Gallary Saved :) ', 'Success');
       },
       (err) => { 

      })

  }
  Update()
  {
   
    this.service.post(this.mainObject, "Api/Gallary/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Gallary Updated :) ', 'Success');
      },
      (err) => { 

      })


  }
 

  Clear()
  {
    this.mainObject=new Albums();
  }

 
  Delete()
  {
    this.service.get("api/Gallary/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new Albums();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Webinar Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  
  Next()
  {
    this.service.getData("api/Gallary/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }

  Previous()
  {
 
    this.service.getData("api/Gallary/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }


}
