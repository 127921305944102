import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { News } from '@modules/maindata/Models/News.model';
import { environment } from 'Sheard/environment';
import { publicService } from 'Sheard/public-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sb-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  BASE_IMG_URL = environment.serverUrl;
  mainObject:News;
  constructor(public service: publicService,private toastr: ToastrService,private sanitization:DomSanitizer)
  {
     this.mainObject=new News();
   }


  ngOnInit(): void {
  }

  onMainVedioChoose(mainVedio:any)
  {
    if (mainVedio.target.files[0]) {
     
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.mainObject.VideoUrl=event.target.result;
      }
      reader.readAsDataURL(mainVedio.target.files[0]);
  }
}

  onMainImageChoose(mainimag:any)
  {
    if (mainimag.target.files[0]) {
     
      var reader = new FileReader();
      reader.onload = (event:any) => {
          this.mainObject.ImageUrl=event.target.result;
      }
      reader.readAsDataURL(mainimag.target.files[0]);
  }
}
getsrc(image:string)
{
  if(image.includes("base"))
  {
     return  this.sanitization.bypassSecurityTrustUrl( image.split(this.BASE_IMG_URL.slice(0,-1))[1]);
  }
  else
  {
     return image;
  }
}

Save()
{
  this.service.post(this.mainObject, "Api/News", "").subscribe(
    (res) => {
      this.mainObject=new News();
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success('News Saved :) ', 'Success');
    },
    (err) => { 

    })
//console.log(this.mainObject);
}

Update()
{
  this.service.post(this.mainObject, "Api/News/Update", "").subscribe(
    (res) => {
      this.mainObject=res;
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success('News Updated :) ', 'Success');
    },
    (err) => { 

    })

}

Delete()
{
  this.service.get("api/News/Delete/"+this.mainObject.Id, "","").subscribe(
    (res) => {
     this.mainObject=new News();
     this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
     this.toastr.success(' News Deleted :) ', 'Success');
    },
    (err) => { 

    })
}

Next()
{
  this.service.getData("api/News/GetNextRow/"+this.mainObject.Id).subscribe(
    data => {
      if(data!=null)
      this.mainObject = data;
      this.mainObject.Date = this.mainObject.Date.slice(0, 10);
      
    },
    error => {
    });
}
Previous()
{

  this.service.getData("api/News/GetPreviousRow/"+this.mainObject.Id).subscribe(
    data => {
      if(data!=null)
      this.mainObject = data;
      this.mainObject.Date = this.mainObject.Date.slice(0, 10);
    },
    error => {
    });
}



}
