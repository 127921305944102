export class ForumStatistics
{
    constructor() 
    {
        this.TopicsCount=0;
        this.PostsCount=0;
        this.RepliesCount=0;
    }

  TopicsCount:number;
  PostsCount:number;
  RepliesCount:number;
}