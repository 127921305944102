import { Component, OnInit } from '@angular/core';
import { CropInfo } from '@modules/maindata/Models/CropInfo.model';
import { publicService } from 'Sheard/public-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sb-crop-info',
  templateUrl: './crop-info.component.html',
  styleUrls: ['./crop-info.component.scss']
})
export class CropInfoComponent implements OnInit {

  Items:CropInfo[];

  constructor(public service: publicService,private toastr: ToastrService) {

    this.Items=[];

    this.getItems();

   }

  ngOnInit(): void {
  }


  getItems()
  {
    this.service.getData("Api/Crops/getAllCrops").subscribe(
      data => {
        if(data!=null)
        this.Items = data;
        console.log(data);
      },
      error => {
      });

  }
  
  deleteitem(Id:any)
  {

  }
  edititem(Id:any)
  {
    var SeedPrice = (<HTMLInputElement>document.getElementById("SeedPrice-"+Id)).value; 
    var HarvestNo = (<HTMLInputElement>document.getElementById("HarvestNo-"+Id)).value;
    
    var RipeningPeriod = (<HTMLInputElement>document.getElementById("RipeningPeriod-"+Id)).value;
    var IrrigationNo = (<HTMLInputElement>document.getElementById("IrrigationNo-"+Id)).value;
    var YieldTo = (<HTMLInputElement>document.getElementById("YieldTo-"+Id)).value;
    var YieldFrom = (<HTMLInputElement>document.getElementById("YieldFrom-"+Id)).value;


    var mainObject=new CropInfo();
    mainObject.Id=Id;
    mainObject.SeedPrice=Number(SeedPrice);
    mainObject.HarvestNo=Number(HarvestNo);

    mainObject.RipeningPeriod=Number(RipeningPeriod);
    mainObject.IrrigationNo=Number(IrrigationNo);
    mainObject.YieldTo=Number(YieldTo);
    mainObject.YieldFrom=Number(YieldFrom);

    this.service.post(mainObject, "Api/CropsInfo/Update", "").subscribe(
      (res) => {
       
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Updated :) ', 'Success');
      },
      (err) => { 

      })
    

  }
}
