import { Component, OnInit } from '@angular/core';
import { Items } from '@modules/maindata/Models/Items.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-game-items',
  templateUrl: './game-items.component.html',
  styleUrls: ['./game-items.component.scss']
})
export class GameItemsComponent implements OnInit {

  Items:Items[];

  constructor(public service: publicService,private toastr: ToastrService) {

    this.Items=[];

    this.getItems();

   }

  ngOnInit(): void {
  }


  getItems()
  {
    this.service.getData("Api/Items/getAllItems").subscribe(
      data => {
        if(data!=null)
        this.Items = data;
        console.log(data);
      },
      error => {
      });

  }
  
  deleteitem(Id:any)
  {

  }
  edititem(Id:any)
  {
    var BuyPrice = (<HTMLInputElement>document.getElementById("BuyPrice-"+Id)).value; 
    var SellPrice = (<HTMLInputElement>document.getElementById("SellPrice-"+Id)).value;
    
    var mainObject=new Items();
    mainObject.Id=Id;
    mainObject.BuyPrice=BuyPrice;
    mainObject.SellPrice=SellPrice;


    this.service.post(mainObject, "Api/Items/Update", "").subscribe(
      (res) => {
       
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Items Update :) ', 'Success');
      },
      (err) => { 

      })
    

  }

}
