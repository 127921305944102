export class Items {

    constructor() {
        this.Id=0;
        this.UnitId=0;
        this.BuyPrice="";
        this.SellPrice="";
        this.ItemName='';
        this.CategoryId=0;
       
    }

    Id:number;
    ItemName:string;
    UnitId:number;
    CategoryId:number;
    BuyPrice:string;
    SellPrice:string;
}