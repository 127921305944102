import { Component, OnInit } from '@angular/core';
import { InfoandInstructions } from '@modules/maindata/Models/InfoandInstructions.model';
import { publicService } from 'Sheard/public-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sb-infoand-instructions',
  templateUrl: './infoand-instructions.component.html',
  styleUrls: ['./infoand-instructions.component.scss']
})
export class InfoandInstructionsComponent implements OnInit {
  mainObject:InfoandInstructions;
  constructor(public service: publicService,private toastr: ToastrService) 
  {
    this.mainObject=new InfoandInstructions();
   }

  ngOnInit(): void {
    this.getInfoandInstructions();
  }

  getInfoandInstructions()
  {
    this.service.getData("Api/InfoAndInstructions/Getall").subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        console.log(data);
      },
      error => {
      });
  }

  Update()
  {
    this.service.post(this.mainObject, "Api/InfoAndInstructions/Update", "").subscribe(
      (res) => {
          console.log(res);
          this.mainObject=new InfoandInstructions();
          this.getInfoandInstructions();
               this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
               this.toastr.success('Saved Success :) ', 'Success');

      },
      (err) => { 
              this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
             this.toastr.error('Saved failed :) ', 'Success');
     
      })
  }


}
