import { Component, OnInit } from '@angular/core';
import { S_WorkShops_Details, S_WorkShops_Partners, WorkShops } from '@modules/maindata/Models/WorkShops.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-workshops',
  templateUrl: './workshops.component.html',
  styleUrls: ['./workshops.component.scss']
})
export class WorkshopsComponent implements OnInit {

  mainObject: WorkShops;
  IDS:string='';
  myexcelFiles:File [] = [];
  myvideoFiles:File [] = [];
  mypdfFiles:File [] = [];
  myimageFiles:File [] = [];
  mypartnersimageFiles:File [] = [];

  constructor(public service: publicService,private toastr: ToastrService) 
  { 
      this.mainObject = new WorkShops();
  }


  
  ngOnInit(): void {

    //this.loadEmployes();
    //this.loadHealthConditions();
    //this.loadSecurityLevels();

  }
  onExcelFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_WorkShops_Excel.push(new S_WorkShops_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myexcelFiles.push(event.target.files[i]);
  }
  
  }
  onVideoFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_WorkShops_Video.push(new S_WorkShops_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.myvideoFiles.push(event.target.files[i]);
  }
 
  }
  onPDFFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_WorkShops_PDF.push(new S_WorkShops_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.mypdfFiles.push(event.target.files[i]);
  }
  
  }

  onPartnersImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_WorkShops_Partners.push(new S_WorkShops_Partners(0,0,"",String(ev.target.result),""));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.mypartnersimageFiles.push(event.target.files[i]);
  }
    
  }

  onImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_WorkShops_Img.push(new S_WorkShops_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);

      this.myimageFiles.push(event.target.files[i]);
  }
    
  }


  onMainImageChoose(mainimag:any)
  {
    //upload file and get new link 
    console.log(mainimag.target.files[0]);

    var reader = new FileReader();
    reader.onload = (ev:any) => {
        this.mainObject.ImageUrl=String(ev.target.result);
    }
    reader.readAsDataURL(mainimag.target.files[0]);

  }
  

  Save()
  {

    for (var i = 0; i < this.mypartnersimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.mypartnersimageFiles[i].name)).value; 
           var inputValueD = (<HTMLInputElement>document.getElementById(this.mypartnersimageFiles[i].name+"_D")).value;     
           this.mainObject.S_WorkShops_Partners[i].Name=inputValue;
           this.mainObject.S_WorkShops_Partners[i].Description=inputValueD;
    }
    for (var i = 0; i < this.myimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.myimageFiles[i].name)).value;     
           this.mainObject.S_WorkShops_Img[i].Title=inputValue;
    }

    for (var i = 0; i < this.myvideoFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myvideoFiles[i].name)).value;     
          this.mainObject.S_WorkShops_Video[i].Title=inputValue;
    }

    for (var i = 0; i < this.myexcelFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myexcelFiles[i].name)).value;     
          this.mainObject.S_WorkShops_Excel[i].Title=inputValue;
    }
    for (var i = 0; i < this.mypdfFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.mypdfFiles[i].name)).value;     
          this.mainObject.S_WorkShops_PDF[i].Title=inputValue;
    }


    this.service.post(this.mainObject, "Api/WorkShops", "").subscribe(
      (res) => {
        this.mainObject=new WorkShops();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New WorkShop Saved :) ', 'Success');
      },
      (err) => { 

      })
  }
  Update()
  {
    
    this.service.post(this.mainObject, "Api/WorkShops/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('WorkShop Updated :) ', 'Success');
      },
      (err) => { 

      })


  }

 


  Clear()
  {
    this.mainObject=new WorkShops();
  }

  Delete()
  {
    this.service.get("api/WorkShops/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new WorkShops();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success(' Case Study Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  Next()
  {
    this.service.getData("api/WorkShops/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }

  Previous()
  {
 
    this.service.getData("api/WorkShops/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        this.mainObject.Date = this.mainObject.Date.slice(0, 10);
        //console.log(data);
      },
      error => {
      });
  }


}

