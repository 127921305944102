import { Component, OnInit } from '@angular/core';
import { CaseStudiesCharts, CaseStudy } from '@modules/maindata/Models/CaseStudy.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-case-studies-charts',
  templateUrl: './case-studies-charts.component.html',
  styleUrls: ['./case-studies-charts.component.scss']
})
export class CaseStudiesChartsComponent implements OnInit {

  CaseStudies:CaseStudy[];
  mainObject:CaseStudiesCharts;

  myexcelFiles:File [] = [];

  constructor(public service: publicService,private toastr: ToastrService) 
  { 
    this.CaseStudies = [];
    this.mainObject=new CaseStudiesCharts();
  }

  ngOnInit(): void {
     this.GetCaseStudies();
  }
  GetCaseStudies()
  {
    this.service.getData("Api/CaseStudies/getAllCaseStudies", "").subscribe(
      (res) => {
        this.CaseStudies=res;
      },
      (err) => { 

      })
  }


  onFilesChange(event:any)
  {
      var reader = new FileReader();
      reader.onload = (ev:any) => {
         this.mainObject.File=String(ev.target.result);
      }
      reader.readAsDataURL(event.target.files[0]);
  }

  Save()
  {
    this.service.post(this.mainObject, "Api/CaseStudies/Charts", "").subscribe(
      (res) => {
       this.mainObject=new CaseStudiesCharts();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Chart Saved :) ', 'Success');
      },
      (err) => { 

      })
  }

  Delete()
  {
    this.service.get("api/CaseStudies/Charts/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new CaseStudiesCharts();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success(' Case Study Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  Next()
  {
    this.service.getData("api/CaseStudies/Charts/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;

        //console.log(data);
      },
      error => {
      });
  }

  Previous()
  {
 
    this.service.getData("api/CaseStudies/Charts/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        //console.log(data);
      },
      error => {
      });
  }
  Update()
  {
   
    this.service.post(this.mainObject, "Api/CaseStudies/Charts/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Case Study Charts Updated :) ', 'Success');
      },
      (err) => { 

      })



  }


}
