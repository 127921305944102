export class News {

    constructor() {
        this.Id=0;
        this.CategoryId=0;
        this.Title='';
        this.Date=new Date().toISOString().slice(0, 10);
        this.ShortDescription='';
        this.LongDescription='';
        this.ImageUrl='';
        this.ExternalLinks='';
        this.ShortDescriptionAr='';
        this.LongDescriptionAr='';
        this.VideoUrl='';

    }


    Id:number;
    Title:string;
    Date:string;
    ShortDescription:string;
    LongDescription:string;
    CategoryId:number;
    ImageUrl:string;
    ExternalLinks:string;
    ShortDescriptionAr:string;
    LongDescriptionAr:string;
    VideoUrl:string;

}