export class Albums {

    constructor() {
        this.Id=0;
        this.Name='';
        this.Date=new Date().toISOString().slice(0, 10);
        this.Description='';
        this.S_VideoGallary=[];
        this.S_ImagesGallary=[];
        
    }


    Id:number;
    Name:string;
    Date:string;
    Description:string;
    S_VideoGallary:Gallary_Details[];
    S_ImagesGallary:Gallary_Details[];
  
}

export class Gallary_Details
{
    constructor(i:number,cs:number,T:string,U:string) {
        this.Id=i|0;
        this.GalleryId=cs|0;
        this.Title=T;
        this.Url=U;
    }
Id:number;
GalleryId:number;
Title:string;
Url:string;

}
