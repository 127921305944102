import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForumStatisticsComponent } from '@modules/maindata/components/COMMUNITY/forum-statistics/forum-statistics.component';
import { ForumusersComponent } from '@modules/maindata/components/COMMUNITY/forumusers/forumusers.component';
import { TopicsComponent } from '@modules/maindata/components/COMMUNITY/topics/topics.component';
import { GameItemsComponent } from '@modules/maindata/components/Game/game-items/game-items.component';
import { GameStatisticsComponent } from '@modules/maindata/components/Game/game-statistics/game-statistics.component';
import { GameUsersComponent } from '@modules/maindata/components/Game/game-users/game-users.component';
import { CaseStudiesChartsComponent } from '@modules/maindata/components/Hub/case-studies-charts/case-studies-charts.component';
import { CaseStudiesExperimentsComponent } from '@modules/maindata/components/Hub/case-studies-experiments/case-studies-experiments.component';
import { CasestudiesComponent } from '@modules/maindata/components/Hub/CaseStudies/casestudies.component';
import { DissemenationMaterialComponent } from '@modules/maindata/components/Hub/dissemenation-material/dissemenation-material.component';
import { DocumentsRepositoryComponent } from '@modules/maindata/components/Hub/documents-repository/documents-repository.component';
import { LinksComponent } from '@modules/maindata/components/Hub/links/links.component';
import { AlbumsComponent } from '@modules/maindata/components/MediaCenter/albums/albums.component';
import { WebinarsComponent } from '@modules/maindata/components/MediaCenter/webinars/webinars.component';
import { WorkshopsComponent } from '@modules/maindata/components/MediaCenter/workshops/workshops.component';
import { HomeSliderComponent } from '@modules/maindata/components/Home/home-slider/home-slider.component';

import { SBRouteData } from '@modules/navigation/models';
import { ContactUsComponent } from '@modules/maindata/components/Home/contact-us/contact-us.component';
import { NewsComponent } from '@modules/maindata/components/Home/news/news.component';
import { CropInfoComponent } from '@modules/maindata/components/Game/crop-info/crop-info.component';
import { InfoandInstructionsComponent } from '@modules/maindata/components/Game/infoand-instructions/infoand-instructions.component';
import { ValuePropositionCanvasComponent } from '@modules/maindata/components/Hub/value-proposition-canvas/value-proposition-canvas.component';
import { BusinessModelCanvasComponent } from '@modules/maindata/components/Hub/business-model-canvas/business-model-canvas.component';
import { CaseStudyEditComponent } from '@modules/maindata/components/Hub/case-study-edit/case-study-edit.component';

const routes: Routes = [
    {
         path: '',
         pathMatch: 'full',
         redirectTo: '/auth/login?t=1',
     },
     {
        path: 'home',
        children: [
            {
                path: 'homeslider',
                data: {
                    title: 'home - home slider',
                    breadcrumbs: [
                        {
                            text: 'home - home slider',
                            link: 'home/homeslider',
                        },
                    ],
                } as SBRouteData,
                component: HomeSliderComponent,
            },
            {
                path: 'contactus',
                data: {
                    title: 'home - contactus',
                    breadcrumbs: [
                        {
                            text: 'home - contactus',
                            link: 'home/contactus',
                        },
                    ],
                } as SBRouteData,
                component: ContactUsComponent,
            },
            {
                path: 'news',
                data: {
                    title: 'home - news',
                    breadcrumbs: [
                        {
                            text: 'home - news',
                            link: 'home/news',
                        },
                    ],
                } as SBRouteData,
                component: NewsComponent,
            }
        ]
    },
    {
        path: 'Hub',
        children: [
            {
                path: 'CaseStudies',
                data: {
                    title: 'Hub - CaseStudies',
                    breadcrumbs: [
                        {
                            text: 'Hub - Case Studies',
                            link: '/Hub/CaseStudies',
                        },
                    ],
                } as SBRouteData,
                component: CasestudiesComponent,
            },
            {
                path: 'Case-Studies-Details/:pid',
                data: {
                    title: 'Hub - Case Studies Details',
                    breadcrumbs: [
                        {
                            text: 'Hub - Case Studies Details',
                            link: '/Hub/CaseStudies',
                        },
                    ],
                } as SBRouteData,
                component: CaseStudyEditComponent,
            },


            {
                path: 'CaseStudiesExperiments',
                data: {
                    title: 'Hub - Case Studies Experiments',
                    breadcrumbs: [
                        {
                            text: 'Hub - Case Studies Experiments',
                            link: '/Hub/CaseStudiesExperiments',
                        },
                    ],
                } as SBRouteData,
                component: CaseStudiesExperimentsComponent,
            },
            {
                path: 'CaseStudiesCharts',
                data: {
                    title: 'Hub - Case Studies Charts',
                    breadcrumbs: [
                        {
                            text: 'Hub - Case Studies Charts',
                            link: '/Hub/CaseStudiesCharts',
                        },
                    ],
                } as SBRouteData,
                component: CaseStudiesChartsComponent,
            },
            {
                path: 'Links',
                data: {
                    title: 'Hub - Links',
                    breadcrumbs: [
                        {
                            text: 'Hub - Links',
                            link: '/Hub/Links',
                        },
                    ],
                } as SBRouteData,
                component: LinksComponent,
            },
            {
                path: 'ValuePropositionCanvas',
                data: {
                    title: 'Hub - Value Proposition Canvas',
                    breadcrumbs: [
                        {
                            text: 'Hub - Value Proposition Canvas',
                            link: '/Hub/ValuePropositionCanvas',
                        },
                    ],
                } as SBRouteData,
                component: ValuePropositionCanvasComponent,
            },
            {
                path: 'BusinessModelCanvas',
                data: {
                    title: 'Hub - Business Model Canvas',
                    breadcrumbs: [
                        {
                            text: 'Hub - Business Model Canvas',
                            link: '/Hub/BusinessModelCanvas',
                        },
                    ],
                } as SBRouteData,
                component: BusinessModelCanvasComponent,
            }
            ,
            {
                path: 'DissemenationMaterial',
                data: {
                    title: 'Hub - Dissemenation Material',
                    breadcrumbs: [
                        {
                            text: 'Hub - Dissemenation Material',
                            link: '/Hub/DissemenationMaterial',
                        },
                    ],
                } as SBRouteData,
                component: DissemenationMaterialComponent,
            }
            ,
            {
                path: 'DocumentsRepository',
                data: {
                    title: 'Hub - Documents Repository',
                    breadcrumbs: [
                        {
                            text: 'Hub - Documents Repository',
                            link: '/Hub/DocumentsRepository',
                        },
                    ],
                } as SBRouteData,
                component: DocumentsRepositoryComponent,
            }
        ],

   
    },
    {
        path: 'MediaCenter',
        children: [
            {
                path: 'Workshops',
                data: {
                    title: 'MediaCenter - Workshops',
                    breadcrumbs: [
                        {
                            text: 'MediaCenter - Workshops',
                            link: '/MediaCenter/Workshops',
                        },
                    ],
                } as SBRouteData,
                component: WorkshopsComponent,
            },
            {
                path: 'Webinars',
                data: {
                    title: 'MediaCenter - Webinars',
                    breadcrumbs: [
                        {
                            text: 'MediaCenter - Webinars',
                            link: '/MediaCenter/Webinars',
                        },
                    ],
                } as SBRouteData,
                component: WebinarsComponent,
            }
            ,
            {
                path: 'Albums',
                data: {
                    title: 'MediaCenter - Albums',
                    breadcrumbs: [
                        {
                            text: 'MediaCenter - Albums',
                            link: '/MediaCenter/Albums',
                        },
                    ],
                } as SBRouteData,
                component:AlbumsComponent ,
            }
        ],

   
    },
    {
        path: 'Community',
        children: [
            {
                path: 'Topics',
                data: {
                    title: 'Community - Topics',
                    breadcrumbs: [
                        {
                            text: 'Community - Topics',
                            link: '/Community/Topics',
                        },
                    ],
                } as SBRouteData,
                component: TopicsComponent,
            },
            {
                path: 'Users',
                data: {
                    title: 'Community - Users',
                    breadcrumbs: [
                        {
                            text: 'Community - Users',
                            link: '/Community/Users',
                        },
                    ],
                } as SBRouteData,
                component: ForumusersComponent,
            }
            ,
            {
                path: 'Statistics',
                data: {
                    title: 'Community - Statistics',
                    breadcrumbs: [
                        {
                            text: 'Community - Statistics',
                            link: '/Community/Statistics',
                        },
                    ],
                } as SBRouteData,
                component:ForumStatisticsComponent ,
            }
        ],

   
    },
    {
        path: 'Game',
        children: [
            {
                path: 'GameUsers',
                data: {
                    title: 'Game - GameUsers',
                    breadcrumbs: [
                        {
                            text: 'Game - GameUsers',
                            link: '/Game/GameUsers',
                        },
                    ],
                } as SBRouteData,
                component: GameUsersComponent,
            },
            {
                path: 'GameStatistics',
                data: {
                    title: 'Game - GameStatistics',
                    breadcrumbs: [
                        {
                            text: 'Game - GameStatistics',
                            link: '/Game/GameStatistics',
                        },
                    ],
                } as SBRouteData,
                component: GameStatisticsComponent,
            }
            ,
            {
                path: 'GameItems',
                data: {
                    title: 'Game - GameItems',
                    breadcrumbs: [
                        {
                            text: 'Game - GameItems',
                            link: '/Game/GameItems',
                        },
                    ],
                } as SBRouteData,
                component: GameItemsComponent,
            }
            ,
            {
                path: 'CropsInfo',
                data: {
                    title: 'Game - GameCropsInfo',
                    breadcrumbs: [
                        {
                            text: 'Game - Crops & Trees Info',
                            link: '/Game/CropsInfo',
                        },
                    ],
                } as SBRouteData,
                component: CropInfoComponent,
            }
            ,
            {
                path: 'InfoandInstructions',
                data: {
                    title: 'Game - Info And Instructions',
                    breadcrumbs: [
                        {
                            text: 'Game - Info And Instructions',
                            link: '/Game/InfoandInstructions',
                        },
                    ],
                } as SBRouteData,
                component: InfoandInstructionsComponent,
            }
        
        ],

   
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },

    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
