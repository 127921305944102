import { Component, OnInit } from '@angular/core';
import { Users } from '@modules/maindata/Models/Users.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-game-users',
  templateUrl: './game-users.component.html',
  styleUrls: ['./game-users.component.scss']
})
export class GameUsersComponent implements OnInit {

  Users:Users[];
  constructor(public service: publicService,private toastr: ToastrService) {

    this.Users=[];
    this.getUsers();
   }

  ngOnInit(): void {
  }

  getUsers()
  {
    this.service.getData("Api/GUsers/getAllUsers").subscribe(
      data => {
        if(data!=null)
        this.Users = data;
        console.log(data);
      },
      error => {
      });

  }
  
  deleteuser(Id:any)
  {
    this.service.get("api/GUsers/Delete/"+Id, "","").subscribe(
      (res) => {
        this.getUsers();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('User Deleted :) ', 'Success');
      },
      (err) => { 
  
      })
  }
}
