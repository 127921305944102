import { Component, OnInit } from '@angular/core';
import { CaseStudy, S_CaseStudiesExperiments, S_CaseStudiesExperimentsFiles, S_CaseStudiesExperiments_Details } from '@modules/maindata/Models/CaseStudy.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-case-studies-experiments',
  templateUrl: './case-studies-experiments.component.html',
  styleUrls: ['./case-studies-experiments.component.scss']
})
export class CaseStudiesExperimentsComponent implements OnInit {

  CaseStudies:CaseStudy[];
  mainObject:S_CaseStudiesExperiments;

  myexcelFiles:File [] = [];
  myvideoFiles:File [] = [];
  myimageFiles:File [] = [];
  filestypes:number[]=[];
  constructor(public service: publicService,private toastr: ToastrService) 
  { 
    this.CaseStudies = [];
    this.mainObject=new S_CaseStudiesExperiments();
  }

  ngOnInit(): void {
     this.GetCaseStudies();
  }
  GetCaseStudies()
  {
    this.service.getData("Api/CaseStudies/getAllCaseStudies", "").subscribe(
      (res) => {
        this.CaseStudies=res;
      },
      (err) => { 

      })
  }



  onFilesChange(event:any)
  {
    var typeid=0;
    for (var i = 0; i < event.target.files.length; i++) { 
      var ext=event.target.files[i].name.substr(event.target.files[i].name.lastIndexOf('.')+1);
     
      if(String(ext)=="pdf"){ typeid=3;}
      if(String(ext)==="doc" || String(ext)==="docx"){typeid=5;}
      if(String(ext)==="xlsx" || String(ext)==="xls" || String(ext)==="csv"){typeid=4;}
      
      this.filestypes.push(typeid);

      var reader = new FileReader();
      reader.onload = (ev:any) => {
         this.mainObject.S_CaseStudiesExperimentsFiles.push(new S_CaseStudiesExperimentsFiles(0,0,"",String(ev.target.result),0));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myexcelFiles.push(event.target.files[i]);

     
  }
  console.log(this.myexcelFiles);
  
  }
  onVideoFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 
      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_CaseStudiesExperiments_Video.push(new S_CaseStudiesExperiments_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);
      this.myvideoFiles.push(event.target.files[i]);
  }
  console.log(this.myvideoFiles);
  }
  onImageFileChange(event:any)
  {
    for (var i = 0; i < event.target.files.length; i++) { 

      var reader = new FileReader();
      reader.onload = (ev:any) => {
          this.mainObject.S_CaseStudiesExperiments_Img.push(new S_CaseStudiesExperiments_Details(0,0,"",String(ev.target.result)));
      }
      reader.readAsDataURL(event.target.files[i]);


      this.myimageFiles.push(event.target.files[i]);
  }
     
  }

  Save()
  {

    for (var i = 0; i < this.myimageFiles.length; i++) 
    { 
           var inputValue = (<HTMLInputElement>document.getElementById(this.myimageFiles[i].name)).value;     
          
           this.mainObject.S_CaseStudiesExperiments_Img[i].Title=inputValue;
    }

    for (var i = 0; i < this.myvideoFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myvideoFiles[i].name)).value;     
          this.mainObject.S_CaseStudiesExperiments_Video[i].Title=inputValue;
    }

    for (var i = 0; i < this.myexcelFiles.length; i++) 
    { 
          var inputValue = (<HTMLInputElement>document.getElementById(this.myexcelFiles[i].name)).value;     
          this.mainObject.S_CaseStudiesExperimentsFiles[i].Title=inputValue;
          this.mainObject.S_CaseStudiesExperimentsFiles[i].FiletypeId=this.filestypes[i];
    }
   

   
    this.service.post(this.mainObject, "Api/CaseStudies/Experiments", "").subscribe(
      (res) => {
        this.mainObject=new S_CaseStudiesExperiments();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('New Case Study Experiment Saved :) ', 'Success');
      },
      (err) => { 

      })
  }

  Update()
  {
   
    this.service.post(this.mainObject, "Api/CaseStudies/Experiments/Update", "").subscribe(
      (res) => {
        this.mainObject=res;
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success('Case Study Experiment Updated :) ', 'Success');
      },
      (err) => { 

      })



  }
  Delete()
  {
    this.service.get("api/CaseStudies/Experiments/Delete/"+this.mainObject.Id, "","").subscribe(
      (res) => {
       this.mainObject=new S_CaseStudiesExperiments();
       this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
       this.toastr.success(' Case Study Deleted :) ', 'Success');
      },
      (err) => { 

      })
  }
  Next()
  {
    this.service.getData("api/CaseStudies/Experiments/GetNextRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        
        //console.log(data);
      },
      error => {
      });
  }
  Previous()
  {
 
    this.service.getData("api/CaseStudies/Experiments/GetPreviousRow/"+this.mainObject.Id).subscribe(
      data => {
        if(data!=null)
        this.mainObject = data;
        //console.log(data);
      },
      error => {
      });
  }

}
