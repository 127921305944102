export class DissemenationMaterial {

    constructor() {
        this.Id=0;
        this.FileTypeId=0;
        this.Title='';
        this.CreatedDate=new Date().toISOString().slice(0, 10);
        this.Description='';
        this.DescriptionAr='';
        this.Url='';
        this.TitleAr='';
        this.Country="0";
   
    }

    Id:number;
    Title:string;
    CreatedDate:string;
    Description:string;
    DescriptionAr:string;
    FileTypeId:number;
    Url:string;
    TitleAr:string;
    Country:string;
    
}



