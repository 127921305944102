export class GameStatistics
{
    constructor() 
    {
        this.UsersCount=0;
       
    }

    UsersCount:number;
  
}