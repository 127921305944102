import { Component, OnInit } from '@angular/core';
import { F_Users } from '@modules/maindata/Models/F_Users.model';
import { ToastrService } from 'ngx-toastr';
import { publicService } from 'Sheard/public-service.service';

@Component({
  selector: 'sb-forumusers',
  templateUrl: './forumusers.component.html',
  styleUrls: ['./forumusers.component.scss']
})
export class ForumusersComponent implements OnInit {

  Users:F_Users[];
  constructor(public service: publicService,private toastr: ToastrService) {

    this.Users=[];
    this.getUsers();
   }

  ngOnInit(): void {
  }

  getUsers()
  {
    this.service.getData("Api/Users/getAllUsers").subscribe(
      data => {
        if(data!=null)
        this.Users = data;
        console.log(data);
      },
      error => {
      });

  }
  deleteuser(Id:any)
  {

  }
}
